<template>
  <div>
    <NavTitle class="mb16" title="商户列表" :showBack="false">
      <template #right>
        <div class="input-row">
          <el-input class="input" placeholder="请输入手机号/公司/姓名/ID, 批量搜索关键词使用英文逗号分隔" clearable v-model="searchWord" @clear="handleClear" @change="getUserList"></el-input>
          <el-button slot="append" type="primary" icon="el-icon-search"  @click="getUserList"></el-button>
        </div>
      </template>
    </NavTitle>
    <div class="pl20 pr20 mt16">
      <el-table
        :data="userList"
        style="width: 100%">
        <el-table-column
          prop="uid"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号">
        </el-table-column>
        <el-table-column
          prop="name"
          label="用户类型">
          <template slot-scope="scope">
            <span>{{scope.row.userType == 1 ? '用户':'商户'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="认证类型">
          <template slot-scope="scope">
            <span>{{scope.row.merType == 0 ? '未认证' : scope.row.merType == 1 ? '个人认证' : '企业认证'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          prop="registerTime"
          label="注册时间">
        </el-table-column>
        <el-table-column
          label="状态">
          <template slot-scope="scope">
            <span>{{statusTypes[scope.row.status] || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="冻结原因">
          <template slot-scope="scope">
            <span>{{scope.row.reason?.c || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="冻结时间">
          <template slot-scope="scope">
            <span>{{formatTime(scope.row.reason?.dt) || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.userType == 2">
              <span class="pointer theme-text" @click="toDetail(scope.row)">详情</span>
              <span v-auth="'MODIFY_ENROLL_RISK_ALLOW_LIST'">
                <span class="gap-line">|</span>
                <el-dropdown @command="handleCommand($event, scope.row)">
                  <span class="el-dropdown-link">
                    <span class="pointer theme-text">更多<i class="el-icon-arrow-down el-icon--right"></i></span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <!-- <div v-auth="'MERCHANT_STATUS_MANAGE'">
                      <el-dropdown-item class="status-blue" :command="1" v-if="scope.row.status == 1 && scope.row.status != 3">冻结</el-dropdown-item>
                      <el-dropdown-item class="status-blue" :command="2" v-if="scope.row.status == 2 && scope.row.status != 3">解冻</el-dropdown-item>
                    </div> -->
                    <div v-auth="'MODIFY_ENROLL_RISK_ALLOW_LIST'">
                      <el-dropdown-item class="status-blue" :command="3" v-if="!scope.row.allowUserInfo">加入脱敏白名单</el-dropdown-item>
                      <el-dropdown-item class="status-blue" :command="4" v-if="scope.row.allowUserInfo">移出脱敏白名单</el-dropdown-item>
                    </div>
                    <el-dropdown-item v-auth="'UNFAST_MRTCHANT_IDENFITY'" class="status-blue" :command="5" v-if="scope.row.merType != 0">解除认证</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
              
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center mt12">
        <el-pagination
          layout="prev, pager, next"
          :total="userList.length">
        </el-pagination>
      </div>
    </div>
    <!-- 商家信息 -->
    <MerchantInfoPopup ref="merInfoPopupRef"></MerchantInfoPopup>
    <!-- 冻结弹窗 -->
    <FreezePopup ref="frezzeRef" @update="getUserList"></FreezePopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import MerchantInfoPopup from './components/MerchantInfoPopup.vue'
import FreezePopup from './components/FreezePopup.vue'
import { searchUserApi, userStatusOperateApi, unfastenAuthBindApi } from '@/api/admin.js'
import { appCtrConfigApi } from '@/api/resConf.js'
export default {
  name: 'UserList',
  components: {
    NavTitle,
    Pagination,
    MerchantInfoPopup,
    FreezePopup
  },
  data() {
    return {
      userList: [],
      searchWord: '',
      statusTypes: {
        1: '正常',
        2: '冻结',
        3: '销户',
      }
    }
  },
  methods: {
    getUserList() {
      if(!this.searchWord.trim()) return
      const formData = {
        searchWord: this.searchWord.trim()
      }
      if(this.searchWord.includes(',')) {
        Object.assign(formData, {
          mobiles: this.searchWord.split(',')
        })
      }
      console.log('formData', formData)
      searchUserApi(formData).then(res => {
        console.log('商户列表', res)
        const list = res.data.searchUsers || []
        this.userList = list.filter(item => {
          if(item.reason) {
            try {
              item.reason = JSON.parse(item.reason) || ''
            } catch (error) {}
          }
          return item.userType == 2
        })
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handleClear() {
      this.userList = []
    },
    merWhiteManage(act, id) {
      const formData = {
        act,
        mids:[id]
      }
      console.log('formData', formData)
      appCtrConfigApi(formData).then(res => {
        console.log('商户加白结果', res)
        this.$tips({message: res.msg, type:'success'})
        this.getUserList()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    userStatusManage(act, uid) {
      const formData = {
        act,
        type: 1,
        uid
      }
      console.log('解冻', formData)
      userStatusOperateApi(formData).then(res => {
        this.$tips({message: res.msg, type:'success'})
        this.getUserList()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    formatTime(time) {
      return time && dateFormatUtil(time, '-', true)
    },
    handleCommand(e, info) {
      if(e == 1) {
        // 冻结
        this.$refs.frezzeRef.open({uid: info.uid})
      }
      if(e == 2) {
        this.userStatusManage(2, info.uid)
      }
      if(e == 3) {
        this.merWhiteManage(3, info.uid)
      }
      if(e == 4) {
        this.merWhiteManage(4, info.uid)
      }
      if(e == 5) {
        this.unfastAuthBind(info)
      }
    },
    toDetail(info) {
      // this.$refs.merInfoPopupRef.open({mid: info.uid})
      this.$router.push({
        path: '/detail/merchant',
        query: {
          mid: info.uid
        }
      })
    },
    unfastAuthBind(info) {
      if(info.merType == 0) return this.$tips({message: '用户未认证，无需解绑', type:'success'})
      this.$confirm(`确定解绑该账号(${info.merType == 1 ? '个人': info.merType == 2 ? '企业员工' : '企业管理员'})吗`).then(action => {
        if(action == 'confirm') {
          const formData = {
            mid: info.uid,
            type: info.merType == 1 ? 1 : 2
          }
          console.log('formData', formData)
          unfastenAuthBindApi(formData).then(res => {
            console.log('解除认证', res)
            this.$tips({message: res.msg, type:'success'})
            this.getUserList()
          }).catch(err => {
            this.$tips({message: err, type:'error'})
          })
        }
      }).catch(err => {})
      
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 600px;
  display: flex;
  align-items: center;
}
</style>